import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactPlayer from "react-player";

import { Grid } from "../ui/GridWrapper";
import { Links } from "../ui/Links";
import { Button } from "../ui/Button";
import { Heading, Heading2, Caption, Paragraph } from "../ui/Typography";
import Transition from "../components/Transition";
import { SEO } from "../components/SEO";

const IntroWrapper = styled.div`
  grid-column: 1 / -1;
  padding-bottom: 85px;
  border-bottom: 1px solid ${(props) => props.theme.colors.color};

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding-bottom: 60px;
    h1 {
      padding-bottom: 30px;
    }
  }
`;

const DescWrapper = styled.div`
  grid-column: 1 / -1;

  @media ${(props) => props.theme.breakpoints.mobile} {
    p {
      &:nth-child(1) {
        padding-bottom: 15px;
      }
    }
  }
`;

const MediaWrapper = styled.div`
  grid-column: 1 / -1;
  padding: 10px 0;
  @media ${(props) => props.theme.breakpoints.mobile} {
    & > div {
      height: 246px;
    }
  }
`;

const BottomWrapper = styled.div`
  grid-column: 1 / -1;
`;

const SubWrapper = styled.div`
  grid-column: span 6;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-gap: 10px;
  padding: 10px 0 25px;
  position: relative;
  border-top: 1px solid ${(props) => props.theme.colors.color};
  &:first-of-type {
    &:after {
      content: "";
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      margin: 0 -5px 0 0;
      border-right: 1px solid ${(props) => props.theme.colors.color};
    }
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-gap: 0px;
  }
`;

function About() {
  const data = useStaticQuery(graphql`
    query About {
      sanityAbout {
        _id
        heading
        description
        mainMedia {
          ... on SanityImg {
            _key
            _type
            caption
            asset {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
            }
          }
          ... on SanityVideo {
            _key
            _type
            alt
            autoplay
            caption
            controls
            loop
            muted
            vimeoURL
          }
        }
        probonoTitle
        probonoDesc
        probonoBtn
        probonoURL
        joinBtn
        joinDesc
        joinTitle
        joinURL
      }
    }
  `);
  return (
    <Transition>
      <IntroWrapper>
        <Grid>
          <Heading grid="1 / span 10" padding="0 0 48px 0">
            {data.sanityAbout.heading}
          </Heading>
          <DescWrapper>
            <Grid>
              {data.sanityAbout.description.split("\n\n").map((node) => {
                return <Paragraph grid="span 2">{node}</Paragraph>;
              })}
            </Grid>
          </DescWrapper>
        </Grid>
      </IntroWrapper>
      <MediaWrapper>
        {data.sanityAbout.mainMedia._type === "img" ? (
          <GatsbyImage
            image={data.sanityAbout.mainMedia[0].asset.gatsbyImageData}
          />
        ) : (
          <ReactPlayer
            url={data.sanityAbout.mainMedia[0].vimeoURL}
            playing={data.sanityAbout.mainMedia[0].autoplay}
            loop={data.sanityAbout.mainMedia[0].loop}
            muted={data.sanityAbout.mainMedia[0].muted}
            controls={data.sanityAbout.mainMedia[0].controls}
            playsinline={data.sanityAbout.mainMedia[0].autoplay}
            pip={false}
            width={"100%"}
            height={"auto"}
            config={{
              vimeo: {
                playerOptions: {
                  byline: false,
                  portrait: false,
                  title: false,
                  responsive: true,
                  color: "EB3329",
                },
              },
            }}
          />
        )}
      </MediaWrapper>
      <BottomWrapper>
        <Grid>
          <SubWrapper>
            <Caption grid="1/-1">{data.sanityAbout.probonoTitle}</Caption>
            <Heading2 grid="1 / span 5" padding="0 0 10px 0">
              {data.sanityAbout.probonoDesc}
            </Heading2>
            <Links to={data.sanityAbout.probonoURL} grid="1 / -1">
              <Button>{data.sanityAbout.probonoBtn}</Button>
            </Links>
          </SubWrapper>
          <SubWrapper>
            <Caption grid="1/-1">{data.sanityAbout.joinTitle}</Caption>
            <Heading2 grid="1 / span 5" padding="0 0 10px 0">
              {data.sanityAbout.joinDesc}
            </Heading2>
            <Links to={data.sanityAbout.joinURL} grid="1 / -1">
              <Button>{data.sanityAbout.joinBtn}</Button>
            </Links>
          </SubWrapper>
        </Grid>
      </BottomWrapper>
    </Transition>
  );
}

export default About;

export const Head = () => <SEO title="About | Pal" />;
